import React, { useRef, useState } from "react";
import { Layout } from "../../../shared/Layout/Layout";
import PhoneInputComponent from "../../../shared/Input/PhoneInputComponent";
import DateSelect from "../../../shared/DateSelect/DateSelect";
import TimeSelect from "../../../shared/TimeSelect/TimeSelect";
import bg from "../../../../images/services/team.jpg";
import emailjs from "@emailjs/browser";
import PhoneInput from "react-phone-input-2";
import toast, { Toaster } from "react-hot-toast";
import Carousel from "../../Carousel";

const Contact = () => {
  const form = useRef();

  const [fileCount, setFileCount] = useState(0);

  const [allConvertedFiles, setAllConvertedFiles] = useState([]);

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    from_name: "",
    university: "",
    country: "",
    course: "",
    email: "",
    phone: "",
    files: [],
    message: "",
  });

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (window.Email) {
      window.Email.send({
        SecureToken: "bfd60f88-2eca-46f2-9da6-e0c6bedb992a",
        To: ["assignmentswala1@gmail.com", "scholarfoundation1@gmail.com"],
        From: "info@sidhuacademia.com",
        Subject: `New Assignement Request from ${formData.from_name}`,
        Body: `
        Name: ${formData.from_name};
        Phone: ${formData.phone};
        Email: ${formData.email};
        University: ${formData.university};
        Course: ${formData.course};
        Country: ${formData.country};
        Message: ${formData.message};
        `,
        Attachments: allConvertedFiles,
      })
        .then(() => {
          console.log("Email sent successfully", window.Email);
          // Clear the file input field
          toast.success("Assignment submitted successfully!");
          document.getElementById("fileInput").value = "";
          setAllConvertedFiles([]);
          setFileCount(0);
          setFormData({
            from_name: "",
            university: "",
            country: "",
            course: "",
            email: "",
            phone: "",
            files: [],
            message: "",
          });
          setLoading(false);
        })
        .catch((error) => {
          console.error("Email sending failed:", error);
          toast.error("Assignment submission failed!");
          setLoading(false);
        });
      // window.Email.send(config)
      //   .then(() => {
      //     console.log("Email sent successfully", window.Email);
      //     // Clear the file input field
      //     toast.success("Assignment submitted successfully!");
      //     document.getElementById("fileInput").value = "";
      //     setAllConvertedFiles([]);
      //     setFileCount(0);
      //     setFormData({
      //       from_name: "",
      //       university: "",
      //       country: "",
      //       course: "",
      //       email: "",
      //       phone: "",
      //       files: [],
      //       message: "",
      //     });
      //     setLoading(false);
      //   })
      //   .catch((error) => {
      //     console.error("Email sending failed:", error);
      //     toast.error("Assignment submission failed!");
      //     setLoading(false);
      //   });
    }
  };

  function convertFile() {
    var reader;
    var dataUri;
    var files = document.getElementById("fileInput").files;
    console.log("Files", files);
    // var file =
    //   document.getElementById("fileInput").files[
    //     document.getElementById("fileInput").files.length - 1
    //   ];
    for (var i = 0; i < files.length; i++) {
      reader = new FileReader();
      var file = files[i];

      setFileCount((prev) => prev + 1);

      reader.readAsBinaryString(file);

      reader.onload = function () {
        var dataUri = "data:" + file.type + ";base64," + btoa(reader.result);
        allConvertedFiles.push({ name: file.name, data: dataUri });

        // Update file count in the div
        setFileCount(allConvertedFiles.length);
      };

      reader.onloadend = function () {
        // If you need to do something when all files are loaded, place it here
      };
    }
  }

  // function convertFile() {
  //   var filesInput = document.getElementById("fileInput");
  //   var files = filesInput.files;
  //   console.log("Files", filesInput);
  //   allConvertedFiles = []; // Clear the array before adding new files

  //   for (var i = 0; i < files.length; i++) {
  //     var reader = new FileReader();
  //     var file = files[i];

  //     reader.readAsBinaryString(file);

  //     reader.onload = function () {
  //       var dataUri = "data:" + file.type + ";base64," + btoa(reader.result);
  //       allConvertedFiles.push({ name: file.name, data: dataUri });

  //       // Update file count in the div
  //       setFileCount(allConvertedFiles.length);
  //     };
  //   }
  // }

  console.log("File Count", fileCount);
  // const sendEmail = async (e) => {
  //   e.preventDefault();

  //   console.log("FormData", formData);

  //   emailjs
  //     .send(
  //       "service_69iybee",
  //       "template_0hcjzmb",
  //       formData,
  //       "KpLOFOL_HlGhtCr1P"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result);
  //         if (result.status === 200) {
  //           toast.success("Assignment submitted successfully!");
  //           setFormData({
  //             from_name: "",
  //             university: "",
  //             country: "",
  //             course: "",
  //             email: "",
  //             // phone: "",
  //             files: [],
  //             message: "",
  //           });
  //         }
  //       },
  //       (error) => {
  //         console.log(error.text);
  //         toast.error("Something went wrong. Please try again!!");
  //       }
  //     );
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    console.log("files", files);
    setFileCount(files.length);
    setFormData((prevFormData) => ({
      ...prevFormData,
      files: files,
    }));
    console.log("form", formData);
  };
  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundPosition: "center center",
        backgroundSize: "cover", // Added to cover the entire container
        backgroundAttachment: "fixed",
      }}
    >
      <div className="bg-gray-700 opacity-95">
        <Toaster position="bottom-left" reverseOrder={true} />
        <Layout>
          <div className="mt-0 py-24  dark:text-slate-200 rounded-md ">
            <div className="  grid grid-cols-1 md:grid-cols-admin2 justify-between items-center md:items-start">
              <div className=" flex flex-col justify-end space-y-6 mt-10 md:mt-0 w-full md:w-4/5">
                <Carousel />
              </div>
              <div>
                <div className=" flex items-center justify-center mt-8 md:mt-0">
                  <form ref={form} onSubmit={submitHandler} className="w-full">
                    <div className="px-4 py-6 w-full space-y-5 lg:w-3/4 bg-slate-800 dark:bg-slate-800 text-primary p-3 rounded-md shadow-lg">
                      <h4 className="text-2xl text-slate-200">
                        Upload
                        <span className="text-2xl text-primary ml-2">
                          Assignment
                        </span>
                      </h4>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter your name"
                          className="h-14 px-3 text-lg w-full border-solid border-2  rounded-md"
                          required
                          name="from_name"
                          value={formData.from_name}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter name of university"
                          className="h-14 px-3 text-lg w-full border-solid border-2  rounded-md"
                          name="university"
                          value={formData.university}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter country name"
                          className="h-14 px-3 text-lg w-full border-solid border-2  rounded-md"
                          name="country"
                          value={formData.country}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter course"
                          className="h-14 px-3 text-lg w-full border-solid border-2  rounded-md"
                          name="course"
                          value={formData.course}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <input
                          type="email"
                          placeholder="Enter email address"
                          className="h-14 px-3 text-lg w-full border-solid border-2  rounded-md"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <PhoneInput
                          inputStyle={{ height: "50px", width: "100%" }}
                          country={"in"}
                          name="phone"
                          inputProps={{
                            name: "phone",
                          }}
                          placeholder="Enter phone number"
                          value={formData.phone}
                          onChange={(phone) =>
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              phone: phone,
                            }))
                          }
                        />
                      </div>
                      <div style={{ position: "relative" }}>
                        <label
                          htmlFor="fileUpload"
                          className="text-slate-200 block mb-2"
                        >
                          Upload Assignment Files (PDF, JPG, PNG, TXT, DOCX
                          etc):
                        </label>
                        {/* <input
                          type="file"
                          id="fileUpload"
                          accept=".pdf, .jpg, .png"
                          multiple
                          className="h-14 w-full opacity-0 absolute bg-slate-200"
                          style={{ zIndex: 1 }}
                          onChange={handleFileChange}
                        /> */}
                        <input
                          multiple
                          className="h-14 w-full opacity-0 absolute bg-slate-200"
                          type="file"
                          accept="image/*,video/*,.pdf,.txt,.html,.js,.css,.odt,.odp,.odf,.docx"
                          name="fichiersSupp"
                          id="fileInput"
                          onChange={convertFile}
                        />
                        <div className="h-14 bg-slate-200 text-lg w-full border-solid border-2 rounded-md flex items-center justify-center">
                          <span className="text-slate-800">
                            {fileCount === 0
                              ? "Choose File"
                              : `${fileCount} ${
                                  fileCount <= 1 ? "file" : "files"
                                } uploaded`}
                          </span>
                        </div>
                      </div>
                      <div>
                        <textarea
                          className="resize rounded-md w-full h-14 px-3"
                          placeholder="Assignment details.."
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                        ></textarea>
                      </div>
                      <button
                        type="submit"
                        disabled={loading}
                        className="bg-primary px-3 text-gray-200 w-full rounded-md py-2 rounded-m border-solid border-2 border-transparent hover:scale-105 transition-all duration-200 delay-100 ease-in-out"
                      >
                        {loading ? "Processing..." : " Submit Now"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    </div>
  );
};

export default Contact;
