import React from "react";
import { Layout } from "../../../shared/Layout/Layout";
import { ComputerDesktopIcon } from "@heroicons/react/24/solid";
import Line from "../../../shared/Line";
import Line2 from "../../../shared/Line2";
import email from "../../../../images/phone.png";
import phone from "../../../../images/phone-2.png";
import bg from "../../../../images/bg.jpg";
import bg2 from "../../../../images/services/team.jpg";

const Features = () => {
  return (
    <div className="pt-14 " id="about">
      <Layout>
        <div className="grid md:grid-cols-2 sm:grid-cols-1 items-center justify-between">
          <div className="image-container md:mr-10 sm:mr-0">
            <div className="image-wrapper ">
              <div
                className="w-full h-full flex justify-start mt-10 "
                style={{ zIndex: -2 }}
              >
                <div className="first-image w-3/5 rounded-md hover:scale-105 transition-all duration-100 ease-out shadow-lg ">
                  <img
                    className="first-image w-100 rounded-md "
                    style={{ zIndex: -2 }}
                    src={bg2}
                    alt="First Image"
                  />
                </div>
              </div>
              <div
                className="w-full h-full flex justify-end"
                style={{ marginTop: "-100px", zIndex: 2 }}
              >
                <div
                  className="second-image text-right w-3/5 rounded-md"
                  style={{ zIndex: 2 }}
                >
                  <img
                    className=" text-right w-full rounded-md"
                    style={{ zIndex: 2 }}
                    src={bg}
                    alt="Second Image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sm:pr-0 md:pr-28 mt-10 md:mt-0">
            <div className="flex items-center gap-2">
              <Line2 />
              <p className="text-sm font-semibold">
                We started our journey in 2023
              </p>
            </div>
            {/* <div className="double bg-primary w-14"></div> */}
            <div>
              <h4 className="text-2xl font-bold">
                Your all-in-one solution for assignment writing requirements.
              </h4>
              <Line />
              <p className="mt-8">
                Witnessing numerous students facing challenges in their
                education, we envisioned a company dedicated to assisting
                students worldwide in their academic pursuits. Our agency boasts
                a team of academic writers covering a wide range of subjects,
                all with international educational backgrounds.
              </p>
            </div>
            {/* <div className="mt-14 flex items-center justify-between">
              <div>
                <img src={phone} alt="phone icon" />
                <h6 className="mt-6 mb-0 text-md font-bold">Phone Number</h6>
                <h2 className="text-xl font-bold">+912454838383</h2>
              </div>
              <div className="relative">
         
                <div className="h-10 w-10 rounded-full border border-primary flex justify-center items-center">
                  <p className="text-sm">OR</p>
                </div>
               
              </div>
              <div>
                <img src={email} alt="email icon" />
                <h6 className="mt-6 mb-0 text-md font-bold">Email Address</h6>
                <h2 className="text-xl font-bold">demo@mail.com</h2>
              </div>
            </div> */}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Features;
