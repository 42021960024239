import React from "react";
import Switcher from "../../DarkModeSwitch/Switcher";
import { pages } from "../../routers";
import { useLocation } from "react-router-dom";
import { Layout } from "./Layout/Layout";
import { InboxIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { HashLink as NavLink } from "react-router-hash-link";
import logo from "../../images/logo.png";

const DesktopHeader = () => {
  const { pathname } = useLocation();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div
      className="hidden md:sticky md:block bg-gray-50 dark:bg-gray-800 top-0 shadow"
      style={{ zIndex: 999999999999999 }}
    >
      {" "}
      <div className="bg-primary py-2">
        <Layout>
          <div className="flex justify-end items-center">
            <div className="flex items-center gap-10 flex-wrap">
              <h6 className="text-gray-50 flex items-center gap-1">
                <PhoneIcon className="h-4 w-4 text-gray-50 group-hover:text-gray-200 transition-all duration-200 delay-75 ease-in-out" />{" "}
                +917011447561
              </h6>
              {/* <h6 className="text-gray-50 flex items-center gap-1">
                <PhoneIcon className="h-4 w-4 text-gray-50 group-hover:text-gray-200 transition-all duration-200 delay-75 ease-in-out" />{" "}
                +91314578637
              </h6> */}
              <h6 className="text-gray-50 flex items-center gap-1">
                <InboxIcon className="h-4 w-4 text-gray-50 group-hover:text-gray-200 transition-all duration-200 delay-75 ease-in-out" />{" "}
                assignmentswala1@gmail.com
              </h6>
            </div>
          </div>
        </Layout>
      </div>
      <Layout>
        <div className=" grid grid-cols-header py-8">
          <div className="flex items-center justify-start">
            <NavLink to={"/"} onClick={scrollToTop}>
              {/* <h2 className="text-2xl font-semibold">
                <span className="text-primary">ASSIGNMENTS</span>
              </h2> */}
              <img src={logo} className="w-60" />
            </NavLink>
          </div>
          <div className="flex items-center justify-end space-x-4">
            {pages
              ?.filter((page) => page.show)
              .map((page) => {
                // Check if it's a hash link or a regular link
                const isHashLink = page.path.startsWith("/#");
                console.log("path", pathname, page.path, window.location.hash);

                return isHashLink ? (
                  <NavLink
                    smooth
                    to={page.path}
                    scroll={(el) =>
                      el.scrollIntoView({ behavior: "smooth", block: "start" })
                    }
                    className={`nav-item ${
                      `/${window.location.hash}` === page.path &&
                      "bg-primary text-white"
                    }`}
                  >
                    {page.value}
                  </NavLink>
                ) : (
                  <NavLink
                    onClick={scrollToTop}
                    to={page.path}
                    scroll={(el) =>
                      el.scrollIntoView({ behavior: "smooth", block: "start" })
                    }
                    className={`nav-item ${
                      `/${window.location.hash}` === "/" &&
                      "bg-primary text-white"
                    }`}
                  >
                    {page.value}
                  </NavLink>
                );
              })}
            <Switcher />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default DesktopHeader;
