import React from "react";
import { Layout } from "../../../shared/Layout/Layout";
import Line from "../../../shared/Line";
import Ourcourses from "../OurCourses/Ourcourses";

const Expertise = () => {
  return (
    <div className="py-24">
      <div className="flex justify-center flex-col items-center px-5">
        <h3 className="text-3xl font-bold">
          OUR <span className="text-primary">EXPERTISE</span>
        </h3>
        <p>
          WE OFFER TOP-NOTCH ASSIGNMENT WRITING SOLUTIONS TO STUDENTS ACROSS THE
          GLOBE, COVERING A WIDE RANGE OF SUBJECTS FROM VARIOUS UNIVERSITIES AND
          COLLEGES
        </p>
        <Line />
      </div>
      <Layout>
        <Ourcourses />
      </Layout>
    </div>
  );
};

export default Expertise;
