import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HashLink as NavLink } from "react-router-hash-link";
import Home from "../components/Home/components/Home";
import DesktopFooter from "../components/DesktopFooter";
import TaskManager from "../components/TaskManager/TaskManager";
import AllCourses from "../components/AllCourses/AllCourses";
import Login from "../components/Auth/Login/Login";
import CourseDetails from "../components/Courses/CourseDetails";
import Admin from "../Admin";
import Ourcourses from "../components/Home/components/OurCourses/Ourcourses";
import Contact from "../components/Home/components/Contact/Contact";
import Features from "../components/Home/components/Features/Features";
import FeedBack from "../components/Home/components/FeedBack/FeedBack";
import WhyUS from "../components/Home/components/WhyUs/WhyUS";

export const pages = [
  {
    path: "/",
    exact: true,
    component: Home,
    key: "home",
    value: "Home",
    show: true,
  },
  // {
  //   path: "/#contact", // Adjust the path to start with '#'
  //   exact: true,
  //   component: Contact,
  //   key: "contact",
  //   value: "Assignment",
  //   show: true,
  // },
  {
    path: "/#about", // Adjust the path to start with '#'
    exact: true,
    component: Features,
    key: "about",
    value: "About",
    show: true,
  },
  {
    path: "/#feedback", // Adjust the path to start with '#'
    exact: true,
    component: FeedBack,
    key: "feedback",
    value: "Feedback",
    show: true,
  },
  {
    path: "/#expertise", // Adjust the path to start with '#'
    exact: true,
    component: Ourcourses,
    key: "expertise",
    value: "Expertise",
    show: true,
  },
  // {
  //   path: "/#us", // Adjust the path to start with '#'
  //   exact: true,
  //   component: WhyUS,
  //   key: "us",
  //   value: "Why Us",
  //   show: true,
  // },
  // ... other pages with adjusted paths
];

const MyRoutes = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return (
            <Route
              key={path}
              element={
                // Use HashLink for paths starting with '#'
                path.startsWith("#") ? (
                  <NavLink smooth to={path}>
                    <Component />
                  </NavLink>
                ) : (
                  <Component />
                )
              }
              path={path}
            />
          );
        })}
        {/* <Route element={<Page404 />} /> */}
      </Routes>
      <DesktopFooter />
    </BrowserRouter>
  );
};

export default MyRoutes;
