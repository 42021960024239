import React from "react";
import Courseimg from "../../../../images/hero-bg.webp";
import { useNavigate } from "react-router-dom";
import { Layout } from "../../../shared/Layout/Layout";
import Line from "../../../shared/Line";
import Badge from "../../../shared/Badge/Badge";
import ButtonPrimary from "../../../Buttons/ButtonPrimary";
import Line2 from "../../../shared/Line2";
import one from "../../../../images/services/1.jpg";
import two from "../../../../images/services/2.jpg";
import three from "../../../../images/services/3.jpg";
import four from "../../../../images/services/4.jpg";
import five from "../../../../images/services/5.jpg";
import six from "../../../../images/services/6.jpg";
import seven from "../../../../images/services/7.jpg";
import eight from "../../../../images/services/8.jpg";

const Ourcourses = () => {
  // const navigate = useNavigate();
  return (
    <div className="pt-5">
      <div className=" grid grid-cols-1 md:grid-cols-4 gap-5">
        <div
          className="rounded-md shadow-md hover:shadow-2xl"
          // onClick={() => navigate(`/course-details/1`)}
        >
          <div className=" h-56 w-full rounded-md">
            <img
              src={one}
              alt="img"
              className=" object-cover rounded-tl-md rounded-tr-md h-full w-full  transition-all delay-200 duration-200 ease-in-out"
            />
          </div>
          <div className=" space-y-4 px-4 py-10">
            <div className=" flex justify-between items-center">
              <h2 className="text-lg font-semibold">
                Information Technology Assignments
              </h2>
              {/* <Badge
                bg={"bg-primary"}
                text={"text-gray-200"}
                content={"Beginner"}
              /> */}
            </div>
            <Line2 />
            {/* <div className=" flex justify-between items-center">
              <p>Lessons: 5</p>
              <p>Tasks: 5</p>
            </div> */}
            <p className="">
              It's a broad spectrum of assignment writing topics, encompassing
              Database, Programming, Data Structures and Algorithms, Operating
              Systems, Networking, and more.
            </p>
            {/* <p className="">
              It is vast assignment writing subject which involves Database,
              Programming, Data Structures and Algorithms, Operating Systems,
              Networking, etc.
            </p> */}
            {/* <div className=" flex justify-between items-center">
              <h6 className=" text-primary font-semibold">Price: Free</h6>
              <button
                className=" border-solid border-2 border-primary hover:bg-primary hover:text-gray-200 transition-colors duration-150 delay-100 ease-in-out rounded-md px-3 py-2"
                onClick={() => navigate(`/course-details/1`)}
              >
                Start Course
              </button>
            </div> */}
          </div>
        </div>
        {/* Start */}
        <div className="rounded-md shadow-md hover:shadow-2xl">
          <div className=" h-56 w-full rounded-md">
            <img
              src={two}
              alt="img"
              className=" object-cover rounded-tl-md rounded-tr-md h-full w-full  transition-all delay-200 duration-200 ease-in-out"
            />
          </div>
          <div className=" space-y-4 px-4 py-10">
            <div className=" flex justify-between items-center">
              <h2 className="text-lg font-semibold">Healthcare Assignments</h2>
            </div>
            <Line2 />

            <p className="">
              Medical assignments are challenging in assignment writing. That's
              why we've decided to hire top-quality medical assignment writers
              from various backgrounds, including Nursing and MBBS.
            </p>
            {/* <p className="">
              Medical Assignments are so tough assignment writing subject to do
              therefore it has been decided to hire best quality medical
              asignment writers from numerous background such as Nursing, MBBS,
              etc.
            </p> */}
          </div>
        </div>
        {/* END */}
        {/* Start */}
        <div className="rounded-md shadow-md hover:shadow-2xl">
          <div className=" h-56 w-full rounded-md">
            <img
              src={three}
              alt="img"
              className=" object-cover rounded-tl-md rounded-tr-md h-full w-full  transition-all delay-200 duration-200 ease-in-out"
            />
          </div>
          <div className=" space-y-4 px-4 py-10">
            <div className=" flex justify-between items-center">
              <h2 className="text-lg font-semibold">Part-Time Jobs Help</h2>
            </div>
            <Line2 />

            <p className="">
              We assist students in finding part-time jobs by posting their
              exact locations and availability on different social platforms.
              Additionally, we offer support and resources to help them navigate
              the job search process effectively.
            </p>
            {/* <p className="">
              We help our students in finding Part-Time Jobs by posting their
              exact locations and availability on different social platforms.
            </p> */}
          </div>
        </div>
        {/* END */}
        {/* Start */}
        <div className="rounded-md shadow-md hover:shadow-2xl">
          <div className=" h-56 w-full rounded-md">
            <img
              src={four}
              alt="img"
              className=" object-cover rounded-tl-md rounded-tr-md h-full w-full  transition-all delay-200 duration-200 ease-in-out"
            />
          </div>
          <div className=" space-y-4 px-4 py-10">
            <div className=" flex justify-between items-center">
              <h2 className="text-lg font-semibold">Engineering Assignments</h2>
            </div>
            <Line2 />

            <p className="">
              We have a team of experienced professors dedicated to engineering
              assignment writing, covering diverse fields such as Mechanical
              Engineering, Civil Engineering, Electrical Engineering, and more.
            </p>
            {/* <p className="">
              We got engineering assignment eriting professors of different
              fields like Mechanical Engineering, Civil Engineering, Electric
              Engineering, etc.
            </p> */}
          </div>
        </div>
        {/* END */}
        {/* Start */}
        <div className="rounded-md shadow-md hover:shadow-2xl">
          <div className=" h-56 w-full rounded-md">
            <img
              src={five}
              alt="img"
              className=" object-cover rounded-tl-md rounded-tr-md h-full w-full  transition-all delay-200 duration-200 ease-in-out"
            />
          </div>
          <div className=" space-y-4 px-4 py-10">
            <div className=" flex justify-between items-center">
              <h2 className="text-lg font-semibold">
                Science and Maths Assignments
              </h2>
            </div>
            <Line2 />

            <p className="">
              Sidhu Assignment Services provides assistance with Science and
              Maths assignments, encompassing Physics, Chemistry, Biology, and
              all levels of Mathematics. Our team comprises top-notch assignment
              writing experts in these subjects.
            </p>
            {/* <p className="">
              Dheeraj Academia helps in every Science and Maths asignments
              including Physics, Chemistry, Biology And all level Maths work. We
              have best assignment writing experts for this.
            </p> */}
          </div>
        </div>
        {/* END */}
        {/* Start */}
        <div className="rounded-md shadow-md hover:shadow-2xl">
          <div className=" h-56 w-full rounded-md">
            <img
              src={six}
              alt="img"
              className=" object-cover rounded-tl-md rounded-tr-md h-full w-full  transition-all delay-200 duration-200 ease-in-out"
            />
          </div>
          <div className=" space-y-4 px-4 py-10">
            <div className=" flex justify-between items-center">
              <h2 className="text-lg font-semibold">
                Finance and Accountings Assignments
              </h2>
            </div>
            <Line2 />

            <p className="">
              We take pride in having the best assignment writing experts in
              Finance and Accounting subjects. Our writers are highly capable
              and adept at resolving all issues within these domains.
            </p>
            {/* <p className="">
              We got the best assignment writing experts of Finance And
              Accounting subjects, in these areas our writers are capable to
              solve all the issues
            </p> */}
          </div>
        </div>
        {/* END */}
        {/* Start */}
        <div className="rounded-md shadow-md hover:shadow-2xl">
          <div className=" h-56 w-full rounded-md">
            <img
              src={seven}
              alt="img"
              className=" object-cover rounded-tl-md rounded-tr-md h-full w-full  transition-all delay-200 duration-200 ease-in-out"
            />
          </div>
          <div className=" space-y-4 px-4 py-10">
            <div className=" flex justify-between items-center">
              <h2 className="text-lg font-semibold">Statistics Assignments</h2>
            </div>
            <Line2 />

            <p className="">
              Though this subject may seem complex, our dedicated teachers are
              committed to delivering timely solutions. We take pride in having
              some of the top assignment writing experts specifically for this
              field.Our commitment extends beyond complexity—timely solutions
              are our specialty.
            </p>
            {/* <p className="">
              This subject seems to be very complex, but our teachers are
              dedicated to deliver on time solutions for this field because we
              have one of the top assignment writing experts for this subject
            </p> */}
          </div>
        </div>
        {/* END */}
        {/* Start */}
        <div className="rounded-md shadow-md hover:shadow-2xl">
          <div className=" h-56 w-full rounded-md">
            <img
              src={eight}
              alt="img"
              className=" object-cover rounded-tl-md rounded-tr-md h-full w-full  transition-all delay-200 duration-200 ease-in-out"
            />
          </div>
          <div className=" space-y-4 px-4 py-10">
            <div className=" flex justify-between items-center">
              <h2 className="text-lg font-semibold">
                Business and Management Assignments
              </h2>
            </div>
            <Line2 />

            <p className="">
              Our top-notch assignment writers specialize in guiding students
              from various universities, colleges, and schools in a multitude of
              subjects within the Business and Management area.
            </p>
            {/* <p className="">
              We have top-notch assignment writers for this subject that can
              guide students from various universities, colleges and school in
              numerous subjects that come in Business and Management area
            </p> */}
          </div>
        </div>
        {/* END */}
      </div>
    </div>
  );
};

export default Ourcourses;
