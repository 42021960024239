import React, { useState } from "react";
import { Layout } from "../../../shared/Layout/Layout";
import Line from "../../../shared/Line";
import img1 from "../../../../images/feedback/1.jpg";
import img2 from "../../../../images/feedback/2.jpg";
import img3 from "../../../../images/feedback/3.jpg";
import img4 from "../../../../images/feedback/4.jpg";
import img5 from "../../../../images/feedback/5.jpg";
import img6 from "../../../../images/feedback/6.jpg";
import img7 from "../../../../images/feedback/7.jpg";
import img8 from "../../../../images/feedback/8.jpg";
import img9 from "../../../../images/feedback/9.jpg";
import img10 from "../../../../images/feedback/10.jpg";
import img11 from "../../../../images/feedback/11.jpg";
import img12 from "../../../../images/feedback/12.jpg";
import img13 from "../../../../images/feedback/13.jpg";
import img14 from "../../../../images/feedback/14.jpg";
import img15 from "../../../../images/feedback/15.jpg";
import img16 from "../../../../images/feedback/16.jpg";
import img17 from "../../../../images/feedback/17.jpg";
import img18 from "../../../../images/feedback/18.jpg";
import img19 from "../../../../images/feedback/19.jpg";
import img20 from "../../../../images/feedback/20.jpg";
import img21 from "../../../../images/feedback/21.jpg";
import img22 from "../../../../images/feedback/22.jpg";
import img23 from "../../../../images/feedback/23.jpg";
import img24 from "../../../../images/feedback/24.jpg";
import img25 from "../../../../images/feedback/25.jpg";
import img26 from "../../../../images/feedback/26.jpg";
import img27 from "../../../../images/feedback/27.jpg";
import img28 from "../../../../images/feedback/28.jpg";
import img29 from "../../../../images/feedback/29.jpg";
import img30 from "../../../../images/feedback/30.jpg";
import img31 from "../../../../images/feedback/31.jpg";
import img32 from "../../../../images/feedback/32.jpg";
import img33 from "../../../../images/feedback/33.jpg";
import img34 from "../../../../images/feedback/34.jpg";
import img35 from "../../../../images/feedback/35.jpg";
import img36 from "../../../../images/feedback/36.jpg";
import img37 from "../../../../images/feedback/37.jpg";
import img38 from "../../../../images/feedback/38.jpg";
import img39 from "../../../../images/feedback/39.jpg";
import img40 from "../../../../images/feedback/40.jpg";
import img41 from "../../../../images/feedback/41.jpg";
import img42 from "../../../../images/feedback/42.jpg";
import img43 from "../../../../images/feedback/43.jpg";
import img44 from "../../../../images/feedback/44.jpg";
import img45 from "../../../../images/feedback/45.jpg";
import img46 from "../../../../images/feedback/46.jpg";
import img47 from "../../../../images/feedback/47.jpg";
import img48 from "../../../../images/feedback/48.jpg";
import img49 from "../../../../images/feedback/49.jpg";
import img50 from "../../../../images/feedback/50.jpg";
import img51 from "../../../../images/feedback/51.jpg";
import img52 from "../../../../images/feedback/52.jpg";
import img53 from "../../../../images/feedback/53.jpg";
import img54 from "../../../../images/feedback/54.jpg";
import img55 from "../../../../images/feedback/55.jpg";
import img56 from "../../../../images/feedback/56.jpg";
import img57 from "../../../../images/feedback/57.jpg";
import img58 from "../../../../images/feedback/58.jpg";
import img59 from "../../../../images/feedback/59.jpg";
import img60 from "../../../../images/feedback/60.jpg";
import img61 from "../../../../images/feedback/61.jpg";
import img62 from "../../../../images/feedback/62.jpg";
import img63 from "../../../../images/feedback/63.jpg";
import img64 from "../../../../images/feedback/64.jpg";
import img65 from "../../../../images/feedback/65.jpg";
import img66 from "../../../../images/feedback/66.jpg";
import img67 from "../../../../images/feedback/67.jpg";
import img68 from "../../../../images/feedback/68.jpg";
import img69 from "../../../../images/feedback/69.jpg";
import img70 from "../../../../images/feedback/70.jpg";
import img71 from "../../../../images/feedback/71.jpg";
import img72 from "../../../../images/feedback/72.jpg";
import img73 from "../../../../images/feedback/73.jpg";
import img74 from "../../../../images/feedback/74.jpg";
import img75 from "../../../../images/feedback/75.jpg";

const FeedBack = () => {
  const allData = [
    { image: img1, text: "Outstanding Results Guaranteed" },
    { image: img2, text: "Our Results - Univ. of Waverhampton" },
    { image: img3, text: "Academic Success" },
    { image: img4, text: "The Assignment Expert" },
    { image: img5, text: "Student Got Good Grades" },
    { image: img6, text: "Student Got Good Grades" },
    { image: img7, text: "Your Path to Success" },
    { image: img8, text: "Top-notch Performance" },
    { image: img9, text: "Academic Excellence" },
    { image: img10, text: "Great Achievements" },
    { image: img11, text: "Excellence in Education" },
    { image: img12, text: "Achieving Excellence Together" },
    { image: img13, text: "Success Stories" },
    { image: img14, text: "Strive for Excellence" },
    { image: img15, text: "The Road to Success" },
    { image: img16, text: "Top Grades, Top Results" },
    { image: img17, text: "Your Success, Our Mission" },
    { image: img18, text: "Academic Triumph" },
    { image: img19, text: "Top Performance" },
    { image: img20, text: "Pathway to Success" },
    { image: img21, text: "Championing Academic Success" },
    { image: img22, text: "Top Marks, Top Achievements" },
    { image: img23, text: "Your Success Story Begins Here" },
    { image: img24, text: "Student Got Good Grades" },
    { image: img25, text: "Mastering Academics" },
    { image: img26, text: "Success Unleashed" },
    { image: img27, text: "Unlock Your Academic Potential" },
    { image: img28, text: "Road to Success" },
    { image: img29, text: "Excelling in Education" },
    { image: img30, text: "Beyond Excellence" },
    { image: img31, text: "Empowering Academic Success" },
    { image: img32, text: "Elevate Your Grades" },
    { image: img33, text: "The Path to Academic Excellence" },
    { image: img34, text: "Mastering the Art of Education" },
    { image: img35, text: "Your Journey to Success" },
    { image: img36, text: "The Roadmap to Academic Triumph" },
    { image: img37, text: "Academic Prowess Unleashed" },
    { image: img38, text: "Student Got Good Grades" },
    { image: img39, text: "Unleash Your Academic Potential" },
    { image: img40, text: "Great Achievements Await" },
    { image: img41, text: "Excellence in Every Assignment" },
    { image: img42, text: "The Gateway to Academic Success" },
    { image: img43, text: "Your Path to Academic Triumph" },
    { image: img44, text: "A+ Journey Begins Here" },
    { image: img45, text: "Elevating Your Academic Experience" },
    { image: img46, text: "Academic Brilliance Unleashed" },
    { image: img47, text: "Student Got Good Grades" },
    { image: img48, text: "Top Grades, Top Achievements" },
    { image: img49, text: "Excelling in Every Subject" },
    { image: img50, text: "Your Academic Success Partner" },
    { image: img51, text: "Unlocking Academic Excellence" },
    { image: img52, text: "Top Results, Every Time" },
    { image: img53, text: "Your Key to Academic Triumph" },
    { image: img54, text: "Mastering the Art of Success" },
    { image: img55, text: "Striving for Academic Excellence" },
    { image: img56, text: "Student Got Good Grades" },
    { image: img57, text: "Your Journey to Academic Glory" },
    { image: img58, text: "Academic Success Unleashed" },
    { image: img59, text: "Excellence in Every Assignment" },
    { image: img60, text: "Unlock Your Academic Potential" },
    { image: img61, text: "Elevate Your Grades" },
    { image: img62, text: "The Path to Academic Excellence" },
    { image: img63, text: "Mastering the Art of Education" },
    { image: img64, text: "Your Journey to Success" },
    { image: img65, text: "The Roadmap to Academic Triumph" },
    { image: img66, text: "Academic Prowess Unleashed" },
    { image: img67, text: "Striving for Excellence" },
    { image: img68, text: "Unleash Your Academic Potential" },
    { image: img69, text: "Great Achievements Await" },
    { image: img70, text: "Excellence in Every Assignment" },
    { image: img71, text: "The Gateway to Academic Success" },
    { image: img72, text: "Your Path to Academic Triumph" },
    { image: img73, text: "Student Got Good Grades" },
    { image: img74, text: "Elevating Your Academic Experience" },
    { image: img75, text: "Academic Brilliance Unleashed" },
  ];

  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(allData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const displayedData = allData.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    // Smooth scroll to the top of the feedback section
    const feedbackSection = document.getElementById("feedback");
    feedbackSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="pt-24" id="feedback">
      <Layout>
        <div>
          <h3 className="text-3xl font-bold">
            OUR <span className="text-primary">FEEDBACK</span>
          </h3>
          <p>SEE OUR FEEDBACK FROM THE STUDENTS</p>
          <Line />
        </div>
        <div className="mt-8 pt-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            {displayedData.map((d, index) => (
              <div
                key={index}
                className="flex hover:scale-95 cursor-pointer transition-all duration-300 ease-in-out relative gap-3"
                style={{ height: "600px" }}
              >
                <div className="flex flex-col justify-center items-center w-1/12">
                  <h4
                    className="text-2xl text-red-800 font-semibold origin-center transform whitespace-nowrap"
                    style={{ rotate: "270deg" }}
                  >
                    {d.text}
                  </h4>
                </div>
                <div className="w-11/12">
                  <img
                    src={d.image}
                    className="w-full h-full object-cover"
                    alt={`feedback-${index}`}
                  />
                </div>
              </div>
            ))}
          </div>
          {totalPages > 1 && (
            <div className="mt-8 flex justify-center space-x-4">
              {window.innerWidth < 768 && currentPage > 1 && (
                <button
                  className="py-2 px-4 rounded-md bg-primary text-white hover:scale-105 transition-all duration-200 ease-in-out"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Prev
                </button>
              )}

              {window.innerWidth < 768 ? (
                <div className="flex items-center space-x-2">
                  <span className="text-lg text-gray-800">
                    {currentPage}/{totalPages}
                  </span>
                </div>
              ) : (
                Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (page) => (
                    <button
                      key={page}
                      className={`py-2 px-4 rounded-md ${
                        currentPage === page
                          ? "bg-slate-200 border border-primary text-primary hover:scale-105 transition-all duration-200 ease-in-out"
                          : "bg-primary text-white hover:scale-105 transition-all duration-200 ease-in-out"
                      }`}
                      onClick={() => handlePageChange(page)}
                    >
                      {page}
                    </button>
                  )
                )
              )}

              {window.innerWidth < 768 && currentPage < totalPages && (
                <button
                  className="py-2 px-4 rounded-md bg-primary text-white hover:scale-105 transition-all duration-200 ease-in-out"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </button>
              )}
            </div>
          )}
        </div>
      </Layout>
    </div>
  );
};

export default FeedBack;
