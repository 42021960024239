import React, { useState } from "react";
import Switcher from "../../DarkModeSwitch/Switcher";
import { pages } from "../../routers";
import { useLocation } from "react-router-dom";
import { Layout } from "./Layout/Layout";
import { InboxIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { HashLink as NavLink } from "react-router-hash-link";
import logo from "../../images/logo.png";

const MobileHeader = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { pathname } = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    closeMenu();
  };

  return (
    <div className="block sticky md:hidden transition-all duration-300 ease-in-out">
      {/* Mobile header content here */}
      <div className="bg-slate-50 dark:bg-slate-800 py-4">
        <Layout>
          <div className="flex justify-between items-center">
            {/* <h2 className="text-2xl font-semibold text-primary">ASSIGNMENTS</h2> */}
            <img src={logo} className="w-52" />
            <div className=" flex justify-end items-center gap-4">
              <button onClick={toggleMenu}>
                <svg
                  className="h-6 w-6 text-primary"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 12h16m-7 6h7"></path>
                </svg>
              </button>
              <Switcher />
            </div>
          </div>
        </Layout>
      </div>

      {/* Mobile navbar menu */}
      {isMenuOpen && (
        <div className="bg-gray-50 dark:bg-gray-800 py-2 transition-transform duration-500 ease-in-out">
          <Layout>
            <div className="flex flex-col items-start space-y-2">
              {pages
                ?.filter((page) => page.show)
                .map((page) => {
                  const isHashLink = page.path.startsWith("/#");

                  return isHashLink ? (
                    <NavLink
                      key={page.value}
                      smooth
                      to={page.path}
                      scroll={(el) =>
                        el.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        })
                      }
                      className={`nav-item ${
                        `/${window.location.hash}` === page.path &&
                        "bg-primary text-slate-50 w-full"
                      }`}
                      onClick={closeMenu}
                    >
                      {page.value}
                    </NavLink>
                  ) : (
                    <NavLink
                      key={page.value}
                      onClick={() => {
                        scrollToTop();
                        closeMenu();
                      }}
                      to={page.path}
                      scroll={(el) =>
                        el.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        })
                      }
                      className={`nav-item ${
                        `/${window.location.hash}` === "/" &&
                        "bg-primary text-white w-full"
                      }`}
                    >
                      {page.value}
                    </NavLink>
                  );
                })}
            </div>
          </Layout>
        </div>
      )}
    </div>
  );
};

export default MobileHeader;
