import React from "react";

import img from "../../../../images/services/why-us.jpg";
import { Layout } from "../../../shared/Layout/Layout";
import Line from "../../../shared/Line";
import { ComputerDesktopIcon } from "@heroicons/react/24/solid";

const WhyUS = () => {
  return (
    <div className=" grid md:grid-cols-2 sm:grid-cols-1">
      <div
        className="h-full hidden md:block"
        style={{
          minHeight: "80vh",
          backgroundImage: `url(${img})`,
          backgroundPosition: "center center",
          backgroundSize: "cover", // Added to cover the entire container
          // backgroundAttachment: "fixed",
        }}
      ></div>
      <div className="bg-gray-800 text-white py-16 px-2 md:px-10">
        {/* <Layout> */}
        <div>
          <h6 className="text-3xl font-semibold">
            Why <span className="text-primary">Choose</span> Us?
          </h6>
          <Line />
        </div>
        <div className=" grid sm:grid-cols-1 md:grid-cols-2 mt-8 gap-8">
          <div className="flex items-start gap-3">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-primary"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>
            </div>
            <div>
              <h5 className="text-lg">Best Academic Writers</h5>
              <p className="opacity-75 text-sm">
                Our organization selectively hires the best experts from
                different parts of the world. This ensures a deep understanding
                of the unique needs and perspectives of international students.
              </p>
              {/* <p className="opacity-75 text-sm">
                Our organization hire the best experts from different parts of
                the world.. especially those who have done their education from
                overseas..So that they understand international students very
                well
              </p> */}
            </div>
          </div>
          <div className="flex items-start gap-3">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-primary"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                />
              </svg>
            </div>
            <div>
              <h5 className="text-lg">Largest Marks Feedback</h5>
              <p className="opacity-75 text-sm">
                Addressing communication challenges is crucial for student
                satisfaction. Therefore, we facilitate direct interaction
                between students and our academic experts to ensure effective
                feedback and support.
              </p>
              {/* <p className="opacity-75 text-sm">
                Communication is the main issue behind any dissatisfied student.
                Hence, to provide proper feedback we help our student to
                interact directly with our academic experts.
              </p> */}
            </div>
          </div>
          <div className="flex items-start gap-3">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-primary"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                />
              </svg>
            </div>
            <div>
              <h5 className="text-lg">Writer's Background</h5>
              <p className="opacity-75 text-sm">
                Our diverse team includes writers from various nations,
                fostering a smooth understanding of all concepts in different
                assignments provided by clients.
              </p>
              {/* <p className="opacity-75 text-sm">
                We hire writer's from many nations of the world which lead to
                smooth understanding of all the concepts of different
                assignments given by the client
              </p> */}
            </div>
          </div>
          <div className="flex items-start gap-3">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-primary"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
                />
              </svg>
            </div>
            <div>
              <h5 className="text-lg">Part-Time Jobs help</h5>
              <p className="opacity-75 text-sm">
                With the largest global student base, we aid international
                students in finding part-time jobs by promoting their posts on
                various social media platforms.
              </p>
              {/* <p className="opacity-75 text-sm">
                Having largest number of global students base, we assist
                international students in part-time jobs by posting their post
                on different social media platforms
              </p> */}
            </div>
          </div>
          <div className="flex items-start gap-3">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-primary"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                />
              </svg>
            </div>
            <div>
              <h5 className="text-lg">Better Communication</h5>
              <p className="opacity-75 text-sm">
                We prioritize swift responses to ensure no student waits for
                long. Quick and efficient solutions are delivered promptly to
                address all client issues.
              </p>
              {/* <p className="opacity-75 text-sm">
                We never allow any student to wait for long...quicker response
                is being delivered to solve all issues of the client
              </p> */}
            </div>
          </div>
          <div className="flex items-start gap-3">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-primary"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                />
              </svg>
            </div>
            <div>
              <h5 className="text-lg">Educational Visa</h5>
              <p className="opacity-75 text-sm">
                We've established a new team dedicated to assisting students in
                obtaining educational visas for universities and colleges
                worldwide.
              </p>
              {/* <p className="opacity-75 text-sm">
                We got a new team which assists the students in receiving
                educational visa across the various universities and colleges in
                the world
              </p> */}
            </div>
          </div>
        </div>

        {/* </Layout> */}
      </div>
    </div>
  );
};

export default WhyUS;
